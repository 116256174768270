<template>
  <div class="search">
    <div class="input">
      <!-- 请输入关键字 -->
      <a-input-search
        v-model:value.trim="state.keyword"
        :placeholder="$t('please_enter_keywords')"
        size="large"
        @search="onSearch"
        @change="onChange"
        maxlength="20"
        allowClear
      >
        <template #enterButton>
          <!-- 搜索 -->
          <a-button type="primary">{{ $t('XB_Search') }}</a-button>
        </template>
      </a-input-search>
    </div>
    <a-spin :spinning="state.spinning">
      <div class="content">
        <div class="type" v-if="state.typeOption.length">
          <!-- 类别 -->
          <ListFilter
            only
            :allObj="false"
            :name="$t('XB_Category')"
            :dataSource="state.typeOption"
            :activeId="state.dataTypes"
            @onChange="switchType"
          />
        </div>
        <div class="list" v-if="state.dataList.length">
          <div class="card">
            <div
              class="card-item"
              v-for="(item, index) in state.dataList"
              :key="index"
              @click="jumpDetail(item)"
            >
              <div
                class="cover"
                :style="{
                  backgroundImage: 'url(' + CoverImage + ')',
                  backgroundSize: 'contain',
                }"
              >
                <img
                  :src="
                    item.CoverImage ||
                    require(`@/assets/image/mine/collect_cover.png`)
                  "
                  :style="{
                    maxWidth: '100%',
                    width: 'auto',
                    background: '#fff',
                  }"
                  alt="cover"
                />
              </div>
              <div class="name">
                <div class="title" v-html="colorChange(item.title)"></div>
              </div>
            </div>
          </div>
          <div class="page-wrap">
            <a-pagination
              show-quick-jumper
              :defaultPageSize="12"
              v-model:current="state.currentPage"
              :show-total="(total) => $t('XB_InTotal', [state.pageTotal])"
              :total="state.pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </div>
        <a-empty
          v-else
          :image="require('@/assets/image/no_data_2.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("search.noData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { reactive, watch, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import ls from '@/utils/local-storage';
const renderVNode = (_, { attrs: { vnode } }) => vnode;

import ListFilter from "@/components/new/filter/ListFilter.vue";

import { searchAll } from "@/api/other";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const router = useRouter();
const route = useRoute();
const store = useStore();

const state = reactive({
  keyword: route.params.keyword,
  spinning: false,
  typeOption: [],
  dataList: [],
  dataTypes: ['course'],
  currentPage: 1,
  pageTotal: 5,
  historyList: ls.get('searchHistory') || [],
  showEmptySearchTip: false,
});
watch(
  () => store.getters.companyInfo,
  (newVal) => {
    if (newVal.menu) {
      let list = [];
      if (newVal.menu.includes("course")) {
        // 在线课程
        list.push({ id: 'course', name: $t("course.online_course") });
      }
      if (newVal.menu.includes("knowledge")) {
        // 知识库
        list.push({ id: 'knowledge', name: $t("XB_KnowledgeBase") });
      }
      if (newVal.menu.includes("project")) {
        // 培训项目
        list.push({ id: 'project', name: $t("MN_TrainingProject_MHome") });
      }
      if (newVal.menu.includes("map")) {
        // 学习地图
        list.push({ id: 'map', name: $t("Pub_LP") });
      }
      if (newVal.menu.includes("newstaff")) {
        // 新人培训
        list.push({ id: 'newstaff', name: $t("cm_newstaff") });
      }
      if (newVal.menu.includes("subject")) {
        // 课程专题
        list.push({ id: 'subject', name: $t("cm_subject") });
      }
      state.typeOption = list;
      if (list.length) {
        state.dataTypes = [list[0].id];
        getList();
      }
    }
  },
  { immediate: true, deep: true }
);

function switchType(val) {
  state.dataTypes = val;
  reload();
}
function onSearch(val) {
  if (!val && state.showEmptySearchTip) {
    return proxy.$message.error($t('please_enter_keywords'))
  }
  if (!val) {
    state.showEmptySearchTip = true;
    return false;
  } else {
    state.showEmptySearchTip = false;
  }
  if (!state.historyList.includes(val)) {
    if (state.historyList.length >= 10) {
      state.historyList.splice(0, 1, val);
    } else {
      state.historyList.push(val);
    }
    ls.set('searchHistory', state.historyList);
  }
  reload()
}
function onChange() {
  if (!state.keyword) {
    state.dataList = [];
    state.pageTotal = 0;
    state.typeOption.forEach(item => item.count = 0);
  }
}
function getList(scroll = false) {
  if (!state.keyword) {
    return false;
  }
  state.spinning = true;
  searchAll({
    page: state.currentPage,
    pageSize: 12,
    keyword: state.keyword,
    dataTypes: state.dataTypes,
  }).then((res) => {
    state.spinning = false;
    state.pageTotal = res.data.totals;
    state.dataList = res.data.list || [];
    if (res.data.dataTypeSatistics) {
      const typeNum = {};
      res.data.dataTypeSatistics.forEach(item => {
        typeNum[item.dataType] = item.dataCount;
      })
      state.typeOption.forEach(item => {
        if (typeNum[item.id] >= 0) {
          item.count = typeNum[item.id]};
        }
      );
    }
    if (scroll) {
      setTimeout(() => {
        document.getElementById("pageTop").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }
  });
}
function reload() {
  state.dataList = [];
  state.currentPage = 1;
  getList();
}
function colorChange(html) {
  const value = state.keyword;
  const regExp = value.toLowerCase();
  const newHtml = html
    .toLowerCase()
    .replace(regExp, '<span  style="color:red;">' + value + "</span>"); //将找到的关键字替换，加上highlight属性；
  return newHtml;
}

function pageChange(n) {
  state.currentPage = n;
  getList(true);
}

function jumpDetail(item) {
  if (item.courseID) {
    router.push({
      path: '/course/detail',
      query: {
        id: item.courseID,
      }
    })
  } else if (item.knowledgeID) {
    router.push({
      path: '/doc/detail',
      query: {
        knowledgeId: item.knowledgeID,
      }
    })
  } else if (item.taskType) {
    const { taskType, taskId } = item;
    switch (taskType) {
      case 1:
        router.push({
          path: '/project/detail',
          query: {
            id: taskId,
          }
        })
        break;
      case 6:
        router.push({
          path: '/map/detail',
          query: {
            id: taskId,
          }
        })
        break;
      case 9:
        router.push({
          path: '/newstaff/detail',
          query: {
            id: taskId,
          }
        })
        break;
      case 5:
        router.push({
          path: '/subject/detail',
          query: {
            id: taskId,
          }
        })
        break;
    }
  }
}
</script>
<style lang="less" scoped>
.search {
  .mixinWrap();
  font-size: 16px;
  .input {
    width: 616px;
    margin: 0 auto;
    padding: 40px 0;
    ::v-deep(.ant-input) {
      height: 33px;
      border-radius: 8px 0 0 8px;
    }
    ::v-deep(.ant-btn) {
      height: 48px;
      padding: 0 28px;
      border-radius: 0 8px 8px 0;
    }
  }
  .card {
    .mixinFlex();
    flex-wrap: wrap;
    margin-top: 30px;
    &-item {
      width: 285px;
      position: relative;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
      transition-property: transform, box-shadow;
      transition-duration: 0.3s;

      box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.1);
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 0 15px 0 rgba(148, 148, 148, 0.25);
      }

      .cover {
        .mixinImgWrap(285px; 160px);
        border-radius: 8px 8px 0 0;
      }
      .name {
        padding: 12px;
        overflow: hidden;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: #333;
          .mixinEllipsis(52px, 2);
        }
      }
    }
  }
  .page-wrap {
    padding: 20px 0;
    text-align: center;
  }
}
</style>
